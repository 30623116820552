.accordion-button:not(.collapsed),
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:focus {
  color: $accordion-button-active-color;
}
.accordion:not(.accordion-flush) {
  .accordion-item:not(:first-of-type) {
    margin-top: .5rem;
    border-top: 1px solid $accordion-border-color;
  }
  .accordion-item {
    border-radius: $border-radius;
    overflow: hidden;
    transition: border-color .15s ease;
    &.active {
      border-color: $accordion-button-active-color;
      // border-color: $accordion-button-focus-border-color;
      // box-shadow: $accordion-button-focus-box-shadow;
    }
  }
}