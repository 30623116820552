.file-manager-grid {
  > .col {
    position: relative;
    > .form-check-input {
      position: absolute;
      margin: .25rem;
      z-index: 2;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: rgba(#fff, .5);
      &:checked {
        background-color: $form-check-input-checked-bg-color;
      }
      &:checked + .card .stretched-link::after {
        background-color: rgba(#000, .1);
      }
      @include media-breakpoint-up($inner-desktop) {
        &:not(:checked) {
          transform: scale(0);
          @include transition(transform .15s ease);
        }
      }
    }
    > .card {
      padding-top: .5rem;
      padding-bottom: .5rem;
      box-shadow: none;
    }
    &:hover > .form-check-input {
      transform: scale(1);
    }
  }
  .stretched-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    text-align: center;
    text-decoration: none;
    > svg {
      color: $warning;
    }
    > img {
      width: 3.75rem;
      height: 3.75rem;
    }
    &::after {
      border-radius: $card-inner-border-radius;
    }
  }
}