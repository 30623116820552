.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}
.dropdown-toggle.no-caret::after {
  content: none;
}
.dropstart .dropdown-toggle::before,
.dropend .dropdown-toggle::after {
  vertical-align: $caret-width * .25;
}
[data-dropdown-hover] + .dropdown-menu {
  margin-top: 0;
}
.dropdown-header {
  font-weight: 700;
}