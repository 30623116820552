.mail-item {
  padding: 0;
  color: $secondary;
  .form-check-input {
    position: absolute;
    top: 50%;
    left: $card-cap-padding-x;
    margin-top: -.5rem;
    &:checked + div {
      background-color: lighten($component-active-bg, 30%);
    }
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem .5rem ($card-cap-padding-x + 1rem);
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  &.unread {
    a {
      color: $dark;
      font-weight: 700;
    }
  }
}
.btn-starred {
  color: $text-muted;
  padding: 0;
  &.active {
    color: #eab308;
  }
}
.email-collapse.collapsing {
  transition: none;
}