.auth-container {
  background-image: url('../img/auth-bg.jpg');
  background-size: 100% 100vh;
  min-height: 100vh;
  padding: $container-padding-x;
  position: relative;
  > .card {
    max-width: 450px;
    margin: auto;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      width: 50%;
      overflow: auto;
      max-width: none;
      .card-body {
        max-width: 450px;
        margin: auto;
      }
    }
  }
}