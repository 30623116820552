.nav-link {
  &.active {
    color: $navbar-light-active-color;
  }
}

.nav-tabs {
  .dropdown-menu {
    @include border-radius($dropdown-border-radius);
  }
}