.input-touchspin {
  flex-wrap: nowrap;
}
.input-touchspin.input-group > .btn-group-vertical:last-child {
  .btn:first-child {
    border-top-left-radius: 0;
  }
  .btn:last-child {
    border-bottom-left-radius: 0;
  }
}
.input-touchspin.input-group > .btn-group-vertical:first-child {
  .btn:first-child {
    border-top-right-radius: 0;
  }
  .btn:last-child {
    border-bottom-right-radius: 0;
  }
}