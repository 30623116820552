// Layout
$desktop: 'xl'; // this value must be the same as the "desktop" value in the "source/html/data.json" file
$inner-desktop: 'lg';  // this value must be the same as the "inner-desktop" value in the "source/html/data.json" file
$main-body-padding: 2rem;
$main-body-padding-sm: 1.5rem; // Small devices and down
$header-height: 3.5rem;
$footer-height: 3.375rem;
$sidebar-width: 13.75rem;
$sidebar-width-mini: 3.5rem;
$vh100: calc(var(--vh, 1vh) * 100); // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

// Simplebar
$simplebar-track-size: .25rem;

// Timeline
$timeline-ruler-width: 2px;
$timeline-ruler-color: $text-muted;
$timeline-circle-width: 16px;
$timeline-circle-border: 2px solid $secondary;
$timeline-gap: 1rem;