.form-control-color {
  &::-moz-color-swatch {
    border-color: transparent;
    @include border-radius($input-border-radius-sm);
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 6%);
  }

  &::-webkit-color-swatch {
    border-color: transparent;
    @include border-radius($input-border-radius-sm);
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 6%);
  }
}