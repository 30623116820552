body.preloading {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-tooltip + 1;
    background-image: url("/assets/img/preloader.svg");
    background-repeat: no-repeat;
    background-position: top center;
  }
  #wrapper {
    opacity: 0;
  }
}
#wrapper {
  transition: opacity .15s linear;
  width: 100%;
  display: flex;
}
#sidebar {
  width: $sidebar-width;
  .offcanvas-header {
    height: $header-height;
  }
  @include media-breakpoint-up($desktop) {
    position: static;
    visibility: visible !important;
    transform: none;
    border-right: 0;
    .btn-close {
      display: none;
    }
  }
  .nav-link {
    font-weight: 500;
  }
}
#main {
  width: calc(100% - #{$sidebar-width});
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: $vh100;
}
#main-header,
#main-footer {
  .navbar {
    padding-left: var(--main-body-padding);
    padding-right: var(--main-body-padding);
  }
}
#main-header {
  position: relative;
  .navbar {
    height: $header-height;
  }
}
#main-body {
  padding: var(--main-body-padding);
}
#main-footer {
  margin-top: auto;
  .navbar {
    height: $footer-height;
  }
}