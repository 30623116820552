.chat-collapse.collapsing {
  transition: none;
}
.chat-body {
  background: url("../img/pattern.png");
  .simplebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
  .popover {
    position: relative;
    max-width: 75%;
    border: 0;
    box-shadow: $box-shadow-sm;
    time {
      font-size: .875em;
      color: $text-muted;
      position: absolute;
      right: .25rem;
      bottom: 0;
    }
  }
  .bs-popover-start {
    align-self: flex-end;
    background-color: #effdde;
    time {
      right: 1rem;
    }
    .check-icon {
      position: absolute;
      right: 0;
      bottom: .125rem;
      color: $text-muted;
    }
  }
}
.chat-footer textarea {
  max-height: 6.25rem;
}
.chat-sidebar {
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus {
    color: $dark;
  }
}