#inner-wrapper {
  display: flex;
  position: relative;
  height: calc(#{$vh100} - #{$header-height} - (var(--main-body-padding) * 2));
  gap: var(--main-body-padding);
  // Mobile view
  @include media-breakpoint-down($desktop) {
    height: calc(#{$vh100} - #{$header-height} - (var(--main-body-padding) * 2) - #{$footer-height});
  }
  // Desktop view
  @include media-breakpoint-up($desktop) {
    .sidebar-fixed &,
    .fixed-footer & {
      height: calc(#{$vh100} - #{$header-height} - (var(--main-body-padding) * 2) - #{$footer-height});
    }
  }
}
#inner-sidebar,
#inner-main {
  > .card {
    height: 100%;
    .card-body {
      height: 100%;
      overflow-y: auto;
    }
  }
}
#inner-sidebar {
  max-width: calc(360px - (var(--main-body-padding) * 2));
  flex-shrink: 0;
}
#inner-main {
  flex: 1 1 auto;
}

// Mobile view
@include media-breakpoint-down($inner-desktop) {
  #inner-sidebar {
    // transform: scale3d(0, 1, 1);
    transform: scaleX(0);
    transform-origin: left;
    @include transition(transform .6s cubic-bezier(.17,.84,.44,1), opacity 0.3s linear);
    position: absolute;
    height: 100%;
    z-index: $zindex-sticky;
    opacity: 0;
    @media (min-width: 361px) {
      > .card {
        box-shadow: 10px 0 15px -3px rgba(0, 0, 0, 0.1),
                    4px 0 6px -2px rgba(0, 0, 0, 0.05) !important;
        @if ($card-border-radius > 0) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    &:not(.w-100) > .card {
      box-shadow: 10px 0 15px -3px rgba(0, 0, 0, 0.1),
                  4px 0 6px -2px rgba(0, 0, 0, 0.05) !important;
      @if ($card-border-radius > 0) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // Expand
  .inner-sidebar-expand {
    #inner-sidebar {
      transform: none;
      opacity: 1;
    }
  }
}

// Desktop view
@include media-breakpoint-up($inner-desktop) {
  // Collapse
  .inner-sidebar-collapse {
    #inner-sidebar {
      display: none;
      transform: translateX(-100%);
    }
  }
}