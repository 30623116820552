@use "sass:math";
.timeline {
  position: relative;
  display: flex;
  flex-direction: column;

  // Vertical ruler
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: (math.div($timeline-circle-width, 2) - math.div($timeline-ruler-width, 2));
    width: $timeline-ruler-width;
    background-color: $timeline-ruler-color;
  }
}

.timeline-item {
  position: relative;
  padding-left: add(math.div($timeline-circle-width, 1), $timeline-gap);
  padding-right: 0;

  // Circle
  &::after {
    content: '';
    position: absolute;
    width: $timeline-circle-width;
    height: $timeline-circle-width;
    right: math.div($timeline-circle-width, 2) * -1;
    background-color: #fff;
    border: $timeline-circle-border;
    top: 21px - math.div($timeline-circle-width, 2);
    left: 0;
    border-radius: 50%;
    z-index: 1;
  }

  > .popover {
    position: relative;
    max-width: 100%;
    z-index: unset;
    > .popover-arrow {
      top: 21px - math.div($timeline-circle-width, 2) - $popover-border-width;
    }
  }
}