.photos-grid {
  > .col {
    > .form-check-input {
      &:checked + .card .stretched-link::after {
        background-color: rgba(#000, .5);
      }
    }
    > .card {
      padding: 0;
      .card-img {
        border-radius: $card-border-radius;
      }
    }
  }
}